import React from 'react';

const About = () => {
  return (
    <section id="about" className="about section">
      <div className="container">
        <h2 className="section-title">About Me</h2>
        <p>I am a passionate web developer and logo designer with expertise in React, Bootstrap, Node.js, Express.js, and MySQL. I create stunning websites and unique logos using Adobe Photoshop and Adobe Illustrator.</p>
      </div>
    </section>
  );
};

export default About;
