import React from "react";
import "../css/home.css";
import Header from "./Header";
import About from "./About";
import Skills from "./Skills";
import ContactGpt from "./ContactGpt";
import Footer from "./Footer";

const Home = () => {
  return (
    <div>
      <Header/>
      <About/>
      <Skills/>
      <ContactGpt/>
      <Footer/>
    </div>
  );
};

export default Home;
