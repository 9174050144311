import React, { useState } from "react";
// import io from "socket.io-client";
import "../css/Contact.css";

// const socket = io.connect("http://localhost:5000");
const Contact = () => {
  const [msgDetails, setMsgDetails] = useState({
    fname: "",
    lname: "",
    email: "",
    relatedService: "",
    description: "",
  });

  const [sendMsgLoading, setSendMsgLoading] = useState(false);

  const onChangeInput = (e) => {
    setMsgDetails({ ...msgDetails, [e.target.name]: e.target.value });
  };

  const handleMsgSubmit = async (e) => {
    e.preventDefault();
    setSendMsgLoading(true);
    const { fname, lname, email, relatedService, description } = msgDetails;

    const response = await fetch("https://api.nurulhasan.info/api/users/sendingusermsgs", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ fname, lname, email, relatedService, description }),
    });

    const json = await response.json();

    if (json.success) {
      setMsgDetails({ fname: "", lname: "", email: "", relatedService: "", description: "" });
      setSendMsgLoading(false);
    }
  };

  return (
    <section id="contact" className="contact section">
      <div className="container">
        <h2 className="section-title">Contact</h2>
        <form onSubmit={handleMsgSubmit}>
          <div className="row">
            <div className="col form-group">
              <label htmlFor="name">First Name</label>
              <input
                type="text"
                className="form-control"
                id="fname"
                onChange={onChangeInput}
                name="fname"
                value={msgDetails.fname}
                placeholder="Enter your first name"
                required
                autoComplete="off"
              />
            </div>
            <div className="col form-group">
              <label htmlFor="name">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="lname"
                onChange={onChangeInput}
                name="lname"
                value={msgDetails.lname}
                placeholder="Enter your last name"
                required
                autoComplete="off"
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              onChange={onChangeInput}
              name="email"
              value={msgDetails.email}
              placeholder="Enter your email"
              required
              autoComplete="off"
            />
          </div>
          <div className="form-group">
            <label htmlFor="relatedService">Related Service</label>
            <select
              className="form-control"
              id="relatedService"
              onChange={onChangeInput}
              name="relatedService"
              value={msgDetails.relatedService}
              required
            >
              <option value="">Choose Related Service</option>
              <option>Web Development</option>
              <option>Logo Design</option>
              <option>Others</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              className="form-control"
              id="description"
              value={msgDetails.description}
              onChange={onChangeInput}
              name="description"
              rows="4"
              placeholder="Enter your message"
              required
            ></textarea>
          </div>
          <button type="submit" className={`btn btn-primary mx-auto d-block ${sendMsgLoading?('progress-bar progress-bar-striped progress-bar-animated'):('')}`}>
            {sendMsgLoading?('Sending...'):('Submit')}
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
