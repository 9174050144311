import React, { useState } from 'react';
import './App.css';
import Layout from './components/Layout';


function App() {
  // eslint-disable-next-line
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const windowOnResize = () => {
    setWindowWidth(window.innerWidth);
  }

  window.addEventListener('resize', windowOnResize);
  return (
    <div className="App">
      <Layout/>
    </div>
  );
}

export default App;
