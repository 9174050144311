import React from 'react';

const Footer = () => {
  return (
    <footer>
      <p>&copy; {new Date().getFullYear()}  Nurul Hasan v1.0. All rights reserved.</p>
      <div className="social-icons">
        <a href="https://www.facebook.com/nur.exists" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
        <a href="https://www.linkedin.com/in/mohammednurulhasan" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
      </div>
    </footer>
  );
};

export default Footer;
